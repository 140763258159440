import React, { useState, useEffect } from "react";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./App.css";
import ProductDisplay from "./components/ProductDisplay";
import moment from "moment";

const Message = ({ message }) => (
  <div dangerouslySetInnerHTML={{ __html: message }} />
);

export default function App() {
  const [message, setMessage] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [htmlContent, setHtmlContent] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(moment());
  const [session_id, setSession_id] = useState("");

  const handleEditorChange = (editorStateHandler) => {
    setEditorState(editorStateHandler);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentcontentAsHTML = convertToHTML(editorState.getCurrentContent());
    setHtmlContent(currentcontentAsHTML);
  };

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };
/*
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success") && query.get("session_id")) {
      const session = query.get("session_id");


      var storage = localStorage.getItem(session);

      const result = JSON.parse(storage);

    //  if (result?.session_id === session) {
        fetch(`/check-session-email.php`, {
          method: "POST", // or 'PUT'
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: result.name,
            session_id: result.session_id,
            text: result.text,
            email: result.email,
            date: result.date,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Success:", data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        localStorage.removeItem(session);

        setMessage("Order placed! You will receive an email confirmation.");
      } /*else {
        setMessage(
          `Something happened with your request. Please send a message to: <a href="mailto:support@timejournal.pt">support@timejournal.pt</a>`
        );
      }*/
   // }
/*
    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);
  */

  return message ? (
    <Message message={message} />
  ) : (
    <ProductDisplay
      editorState={editorState}
      setEditorState={setEditorState}
      handleEditorChange={handleEditorChange}
      htmlContent={htmlContent}
      name={name}
      setName={setName}
      email={email}
      setEmail={setEmail}
      session_id={session_id}
      setSession_id={setSession_id}
      handleChangeDate={handleChangeDate}
      date={date}
      setMessage={setMessage}
    />
  );
}
