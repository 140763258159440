import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import Typewriter from "typewriter-effect";

import { Button, TextField } from "@mui/material";
import { Container } from "@mui/system";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
const ProductDisplay = ({
  editorState,
  setEditorState,
  handleEditorChange,
  htmlContent,
  name,
  setName,
  email,
  setEmail,
  handleChangeDate,
  session_id,
  setSession_id,
  date,
  setMessage,
  message,
}) => {
  const [disabled, setDisabled] = useState(false);
  const onClick = () => {
    setDisabled(true);
    fetch(`/create-checkout-session.php`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success == true) {
          setSession_id(data.response.session_id);
          data.response.text = htmlContent;
          data.response.name = name;
          data.response.email = email;
          data.response.date = date;
          console.log(data);

          localStorage.setItem(
            data.response.session_id,
            JSON.stringify(data.response)
          );
          window.location = data.response.url;
        }
      })
      .catch((error) => {
        setDisabled(false);
        console.error("Error:", error);
      });
  };

  const submitPage = () => {
    fetch(`/check-session-email.php`, {
      method: "POST", // or 'PUT'
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        //session_id: result.session_id,
        text: htmlContent,
        email: email,
        date: date,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  } 
  const today = new Date();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div className="header">
        <div className="header-container">
          <div style={{ textAlign: "center" }}>
            <h4 className="into-text" style={{ textAlign: "center" }}>
              <b>Write Today, Reflect on the Future with</b>
            </h4>
          </div>
          <h1 style={{ textAlign: "center" }}>Time Journal</h1>

          <h2>
            <Typewriter
              options={{
                strings: [
                  "Leave a message for your future self",
                  "Compare your past and present",
                  "Track your progress and set new goals",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </h2>
        </div>
      </div>
      <Container className="padding-y">
        <Grid item xs={12} md={12} className="text-container">
          <h4>
            Welcome to Time Journal, where you can preserve your memories for a
            lifetime. Our website allows you to choose a future date, write a
            text about anything you want, and receive it back via email on that
            date.
          </h4>
          <h4>
            Reflect on your thoughts, emotions and experiences and see how they
            compare to your current reality. Track your personal growth and gain
            insight into your life.
          </h4>
          <h5 className="pt-3">
            <b>Choose a date in the future</b>
          </h5>
          <p>
            Choose a date in the future and create a time capsule of your
            thoughts, feelings, and memories with Time Journal. Imagine being
            able to look back on your past self and see how far you've come.
          </p>
          <h5>
            <b>Write a text about anything you want</b>
          </h5>
          <p>
            Write a text about anything you want and preserve it for future with
            Time Journal. From your thoughts and emotions to your memories and
            experiences, Time Journal allows you to capture your story and
            reflect on it in the future.
          </p>
          <h5>
            <b>Receive your text via email on the chosen date</b>
          </h5>
          <p>
            Preserve your memories for a lifetime with Time Journal. Receive
            your text via email on the chosen date, reflect on your past and
            compare it to your present. Time Journal is your personal time
            machine
          </p>
          <h3 className="pt-3">
            Start your time capsule today and see how far you've come.{" "}
          </h3>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Select Your Delivery Date"
                inputFormat="DD/MM/YYYY"
                value={date}
                onChange={handleChangeDate}
                minDate={today}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <div
            style={{
              display: "flex",
              margin: "0.5rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <Editor
                editorClassName="editorClassName"
                wrapperClassName="wrapperClassName"
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
              />
            </div>
          </div>
          <div style={{ textAlign: "center", paddingTop: "10px" }}>
            <button
              className="button-submit"
              onClick={submitPage}
              disabled={disabled}
            >
              Send message
            </button>
            By clicking you're accepting the{" "}
            <a
              href="https://timejournal.pt/terms-and-conditions.html"
              target="_blank"
            >
              terms and conditions
            </a>
            .
          </div>
        </Grid>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}></Box>
      </Modal>
    </div>
  );
};

export default ProductDisplay;
